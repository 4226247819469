<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 240 400"
  ><path
    fill="currentColor"
    fill-rule="evenodd"
    d="M208 0h-96C94 0 80 14 80 32v17-1c0 18-14 32-32 32H32C14 80 0 94 0 112v256c0 18 14 32 32 32h96c18 0 32-14 32-32v-16c0-18 15-32 32-32h16c18 0 32-14 32-32V32c0-18-14-32-32-32Zm-48 240v49-1c0 18-14 32-32 32h-16c-17 0-32-14-32-31V112c0-18 15-32 32-32h16c18 0 32 14 32 32v128Z"
    clip-rule="evenodd"
  /></svg>
</template>
